<template>
  <div v-if="loaded">
    <div class="subheader pb-5 subheader-transparent" id="kt_subheader">
      <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap pl-0 py-0">
        <div class="d-flex align-items-center flex-wrap mr-1">
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <h4 class="text-dark font-weight-bolder my-1 mr-5">{{ $t('offer.Offers_From_Partners') }}</h4>
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bolder p-0 my-2 font-size-sm">
              <li class="breadcrumb-item">
                <router-link to="/offers" v-slot="{ href, navigate }">
                  <a :href="href" @click="navigate" class="text-muted">{{ $t('offer.Offer_Catalog') }}</a>
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <span class="text-muted">{{ info.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="card card-custom p-8">
          <div>
            <div class="card card-custom bgi-no-repeat bgi-size-cover offer-card"
                 style="height: 220px; background-position: center;"
                 :style="{'background-image': 'url(' + setImgByScreenWidth(info.imageUrl) + ')'}">

              <div class="card-body d-flex align-items-start flex-column justify-content-end p-5" v-if="info.discount">
                <span class="label label-md label-danger label-inline font-weight-bolder">{{ $t('offer.Discount') }} {{ info.discount }}%</span>
              </div>

            </div>
          </div>
          <div class="card-header flex-wrap border-0 pt-1 pl-0 pb-0 mb-4">
            <div class="card-title mb-0">
              <span style="width: 70%; overflow: hidden;" class="card-label font-weight-bolder font-size-h4">
                {{ info.name }}
              </span>
            </div>
            <span class="text-muted font-weight-bolder">{{ info.partner_name }}</span>
          </div>
          <div>
            <span v-if="info.promocode">
              <div class="mb-1">{{ $t('offer.Promo_Code') }}:</div>
              <div class="input-group">
                <input type="text" class="form-control" :placeholder="$t('offer.Promo_Code')" disabled v-model="info.promocode">
                <div class="input-group-append mb-4">
                  <button class="btn btn-light-primary" v-clipboard="info.promocode" :title="$t('offer.Copy_Promo_Code')">
                    <font-awesome-icon icon="copy" class="icon-md"/>
                  </button>
                </div>
              </div>
            </span>

            <span v-if="info.flyerUrl">
              <div class="mb-1">{{ $t('offer.Flyer') }}:</div>
              <a class="btn btn-block btn-light-primary mb-4" :href="info.flyerUrl" target="_blank">
                {{ $t('offer.Download_Flyer') }}
              </a>
            </span>

            <span v-if="info.redirect">
              <div class="mb-1">{{ $t('offer.Request_For_Offer') }}:</div>
              <a class="btn btn-block btn-light-primary mb-4" @click="sendRequest()">
                {{ $t('offer.Send_Request') }}
              </a>
            </span>

            <span v-if="info.link">
              <div class="mb-1">{{ $t('offer.Get_An_Offer') }}:</div>
              <a class="btn btn-block btn-light-primary mb-4" :href="info.link" target="_blank">
                {{ $t('offer.Go_To_The_Partner’s_Page') }}
              </a>
            </span>

            <span v-if="info.tags && info.tags.length > 0">
              <div class="mb-1">{{ $t('offer.Tags') }}:</div>
              <div class="mb-4">
                <span class="label label-md label-light-success label-inline font-weight-bolder mr-4" v-for="item in info.tags" :key="item.id">{{ item }}</span>
              </div>
            </span>

            <div class="mb-1">{{ $t('offer.Offer_Valid_For_Spaces') }}:</div>
            <div v-if="info && locations.length">
              <span class="font-weight-bolder font-size-h5" v-for="item in info.locations" :key="item.id">
                {{ locations.filter(location => location.id == item.id)[0] ? (locations.filter(location => location.id == item.id)[0]).name : null }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{ $t('offer.How_To_Use_It?') }}</h3>
            </div>
          </div>
          <div class="card-body" v-if="info.description">
            <span v-html="info.description.replace(/(?:\r\n|\r|\n)/g, '<br />')" v-linkified></span>
          </div>
        </div>
      </div>
    </div>

    <CoreAlert :title="$t('offer.Request_For_Get_Offer')"
                :closeButtonTitle="$t('offer.Close')"
                closeButtonColor="light-primary"
                icon="ok"
                :show.sync="alertSuccessRequestOffer">
      <template>
        <div class="font-weight-bolder font-size-h3 mb-4">{{ $t('offer.The_Request_Has_Been_Successfully_Sent!') }}</div>
        <div class="text-center">{{ $t('offer.Administrator_Will_Contact_You') }}</div>
      </template>
    </CoreAlert>

    <CoreAlert :title="$t('offer.Request_For_Get_Offer')"
                :closeButtonTitle="$t('offer.Close')"
                closeButtonColor="light-primary"
                icon="fail"
                :show.sync="alertErrorRequestOffer">
      <template>
        <div class="font-weight-bolder font-size-h3">{{ $t('offer.An_Error_Has_Occurred') }}</div>
        <div class="font-weight-bolder font-size-h3">{{ $t('offer.Please_Try_Again_Later') }}</div>
      </template>
    </CoreAlert>

  </div>
</template>


<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import api from '@/plugins/api';
import CoreAlert from '@/components/core/Alert';

let cookie = require('vue-cookie');

export default {
  components: { CoreAlert },
  computed: {
    ...mapGetters({
      loaded: 'offers/loaded',
      info: 'offers/info',
      identity: 'user/identity',
      locations: 'offers/locations',
    })
  },
  data() {
    return {
      promoCode: '',
      space: '',
      spaces: ['123', '321'],
      alertSuccessRequestOffer: false,
      alertErrorRequestOffer: false,
    }
  },
  mounted() {
    this.LocationList();
    this.fetch();
  },
  watch: {
  },
  methods: {
    ...mapActions('offers', [
      'LocationList',
    ]),
    fetch() {
      this.$store.dispatch('offers/GetInfo', this.$route.params.id)
    },
    sendRequest() {
      let user_data = {
        full_name: this.identity.fio,
        email: this.identity.email,
        phone: this.identity.phone,
      }
      api.post('/v2/offers/' + this.info.id, user_data)
        .then(responce => {
          this.alertSuccessRequestOffer = true;
        }).catch(reason => {
          this.alertErrorRequestOffer = true;
        })
    },
    setImgByScreenWidth(url) {
      if (url) return url.replace('325x-', '600x-');
    }
  }
}
</script>

<style scoped>
  .offer-card {
    position: relative;
  }
  .offer-card .card-body {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #00000000 0%, #0000001A 64%, #000000 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
  }
</style>
